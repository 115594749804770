<template>
  <div class="payment-page d-flex justify-content-center align-items-center">
    <div>
      <div class="text-center mb-4">
        <img
          :src="
            isReturn === 'CANCEL'
              ? require('../assets/img/errorBuy.png')
              : require('../assets/img/successBuy.png')
          "
          alt="error"
        />
      </div>
      <div class="text-center">
        <h2
          class="card-title font-weight-bold"
          :style="{ color: isReturn === 'CANCEL' ? '#ff0000' : '#50b748' }"
        >
          {{
            isReturn === "CANCEL"
              ? $t("notSuccessPayment")
              : $t("successPayment")
          }}
        </h2>
        <p class="mb-3">
          {{ $t("transaction") }}
          {{
            isReturn === "CANCEL" ? $t("errorMessage") : $t("successMessage")
          }}
          {{ $t("transactionOneText") }}
          <span class="font-weight-bold">"mail@flinktax.de"</span>
          {{ $t("transactionTwoText") }}
        </p>
        <h6 v-if="isReturn === 'SUCCESS'">{{ $t("comeBackWebsite") }}</h6>
        <h6 v-if="isReturn === 'CANCEL'">{{ $t("comeBackPayment") }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isReturn: "",
    };
  },
  mounted() {
    this.isReturn = this.$route.query.type;
    window.opener.postMessage(
      { message: "requestCheck", answer: this.isReturn },
      "*"
    );
    // window.ReactNativeWebView?.postMessage("requestCheck");
    setTimeout(() => {
      window.close();
      window.opener.external.comeback();
    }, 4000);
  },
};
</script>

<style lang="scss">
.payment-page {
  height: 100vh;
  p {
    font-size: 16px;
  }
}
</style>